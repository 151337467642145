import React, { Component } from "react";
import "./FormSuccess.scss";
import "../ContactUsForm.scss";
import { ReactComponent as ThanksIcon } from "../../../assets/SVGs/success-bubble.svg";

class FormSuccess extends Component {
  constructor() {
    super();
    this.state = {
      clicked: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      clicked: true,
    });
  }

  render() {
    return (
        <div className="contact-us-form">
            <div id="success-icon">
                <ThanksIcon />
            </div>
            <div id="success-text">
                <div className="title">Thank You!</div>
                <div className="subtitle">
                We are reviewing the information that you provided. We'll be in
                touch in the next few days.
                </div>
            </div>
      </div>
    );
  }
}

export default FormSuccess;
