import React from "react";
import "./Button.scss";


function Button(props) {

	const handleClick = () => {
		if (props.onClick) {
			props.onClick(true);
		}
	}

	return (
		<button className={`button ${props.disabled ? 'button-disabled' : 'button-enabled'}`} type="button" disabled={props.disabled || false} onClick={() => handleClick()}>
			<div className="button-text">
				{props.children || props.label || "SUBMIT"}
			</div>
		</button>
	)
}

export default Button;