import React from "react";
import { Routes, Route } from "react-router-dom";
import Navigator from "./Navigator";
import {
  Home,
  Policy,
  TermsOfUse,
} from '../pages';
import {
  HOME,
  POLICY,
  TERMS_OF_USE,
} from './constants.js';


export default function MainRoutes(props) {

  return (
    <Routes>
      <Route path={HOME} element={<Navigator><Home mobile={props.mobile} screenSize={props.screenSize} /></Navigator>} />
      <Route path={POLICY} element={<Navigator><Policy mobile={props.mobile} screenSize={props.screenSize} /></Navigator>} />
      <Route path={TERMS_OF_USE} element={<Navigator><TermsOfUse mobile={props.mobile} screenSize={props.screenSize} /></Navigator>} />
    </Routes>
  );
}

  

