import React from "react";
import "./Divider.scss"

function Divider(props) {

	const styles = {
		...props.style || {},
		height: props.vertical ? '100%' : props.horizontal ? props.width : props.width,
        width: props.vertical ? props.width : props.horizontal ? '100%' : '100%',
        backgroundColor: props.light ? "#000" : props.dark ? '#FFF' : '#FFF',
        borderRadius: props.round ? '2000px' : '0px'
	};

	return (
		<div id="divider" style={styles}></div>
	)
}

export default Divider;