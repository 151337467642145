import React, { Component } from "react";
import "./BackToTop.scss";
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronUp,
} from '@fortawesome/free-solid-svg-icons';


const transitionStyles = {
	entering: { opacity: 1, visibility: 'visible', },
	entered:  { opacity: 1, visibility: 'visible', },
	exiting:  { opacity: 0, visibility: 'hidden', },
	exited:   { opacity: 0, visibility: 'hidden', },
};

class BackToTop extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
		};
	}

	handleScroll = (e) => {
		const scrollPosition = window.scrollY;
		if (scrollPosition >= (this.props.appear || 1400)) {
			if (!this.state.show) {
				this.setState({show: true});
			}
		} else {
			if (this.state.show) {
				this.setState({show: false});
			}
		}
	}

	handleClick = () => {
		if (this.props.scrollID) {
			const cleanID = this.props.scrollID.slice(0,1) === "#" ? this.props.scrollID.slice(1) : this.props.scrollID;
			document.getElementById(cleanID).scrollIntoView();
		} else {
			document.getElementsByTagName('html')[0].scrollIntoView();
		}
	}


	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	render() {
		return (
			<Transition in={this.state.show} timeout={this.props.duration || 0.25}>
					{tState => (
						<div
							style={{
								transition: `opacity ${this.props.duration || 0.25}s ease-in-out, visibility ${this.props.duration || 0.25}s ease-in-out`,
								opacity: 0,
								visibility: 'hidden',
								...transitionStyles[tState],
							}}
							onClick={() => this.handleClick()}
							className="back-to-top"
						>
							<div className="btt-container">
								<div className="btt-text">{this.props.children || this.props.text || <FontAwesomeIcon icon={faChevronUp} className="btt-text-icon" />}</div>
							</div>
						</div>
					)}
				</Transition>
		)
	}
}

export default BackToTop;