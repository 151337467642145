import React, { Component } from "react";
import "./TextArea.scss";


class TextArea extends Component {
	constructor() {
		super();
		this.state = {
			value: '',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		this.setState({value: e.target.value},() => {
			if (this.props.return) {
				this.props.return(this.props.placeholder,this.state.value);
			}
		});
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.clear && this.props.clear && this.state.value !== '') {
			this.setState({value: ''},() => {
				this.props.return(this.props.placeholder,this.state.value);
			})
		}
	}

	render() {
		return (
			<div className="cc-text-area">
				<p style={{display:this.props.includeTitle}}>{this.props.title || ""}</p>
				<textarea className="text-area" placeholder={this.props.placeholder || ""} value={this.state.value} required={this.props.required || false} onChange={(e) => this.handleChange(e)} />
			</div>
			)
	}
}

export default TextArea;