
// Breakpoints
export const XS = 0;
export const SM = 576;
// export const SM = 0;
export const MD = 768;
// export const MD = 641;
export const LG = 992;
// export const LG = 1008;
export const XL = 1200;
export const XXL = 1400;

export const MOBILE_BREAK = XL;

export const MOBILE_JSON = (inKey) => {
  const valueJSON = {
    XS: XS,
    SM: SM,
    MD: MD,
    LG: LG,
    XL: XL,
    XXL: XXL,
  };
  const keyJSON = {
    [`${XS},${SM}`]:'XS',
    [`${SM},${MD}`]:'SM',
    [`${MD},${LG}`]:'MD',
    [`${LG},${XL}`]:'LG',
    [`${XL},${XXL}`]:'XL',
    [`${XXL},${XXL}`]:'XXL',
  }
  if (inKey) {
    if (inKey >= valueJSON.XXL) {
      return keyJSON[`${XXL},${XXL}`];
    } else {
      const keyArr = Object.keys(keyJSON);
      for (let i = 0;i<keyArr.length;i++) {
        const checkArr = keyArr[i].split(',').map((e) => parseInt(e));
        if (inKey >= checkArr[0] && inKey < checkArr[1]) {
          return keyJSON[keyArr[i]];
        }
      }
    }
  } else {
    return {
      ...valueJSON,
      ...keyJSON,
    };
  }
}