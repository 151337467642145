import React, { Component } from "react";
import { createPortal } from "react-dom";
import styles from "./PopUp.module.scss";
import { Transition } from 'react-transition-group';
import { ReactComponent as BLogo } from "../../assets/SVGs/chordCash-Logomark.svg";

const duration = 0.25;
const timeout = 200;

const defaultStyle = {
	transition: `right ${duration}s ease-out`,
	right: '-200%',
};

const transitionStyles = {
	entering: {right: '4%'},
	entered:  {right: '2%'},
	exiting:  {right: '4%'},
	exited:   {right: '-200%'},
};


class PopUp extends Component {
	constructor() {
		super();
		this.state = {
			open: false,
		};
	}

	render() {
		const rootDOM = document.getElementById('root');
		return createPortal(
			<Transition
				in={this.props.open !== undefined ? this.props.open : this.state.open}
				timeout={timeout}
			>
				{tState => (
					<div
						className={styles.popUp}
						style={{
							...defaultStyle,
							...transitionStyles[tState]
						}}
					>
						{
							this.props.logo ?
								<div className={styles.logo}>
									<BLogo />
								</div>
							:
								<></>
						}
						<div className={styles.content}>
							{
								this.props.title ?
									<div className={styles.title}>
										{this.props.title}
									</div>
								:
									<></>
							}
							<div className={styles.text}>
								{this.props.children || this.props.text || ""}
							</div>
						</div>
					</div>
				)}
			</Transition>,
			rootDOM
		)
	}
}

export default PopUp;