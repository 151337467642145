export const HOME = "/";
export const POLICY = "/multiple-funding-applications-policy";
export const TERMS_OF_USE = "/termsofuse";

/* Constants for API */
export const API_URL = process.env.REACT_APP_API_URL;
export const CONTENT_LIST = "/user/content-list";
export const FEATURED_CONTENT = "/user/featured-content";
export const ARTICLE_DETAIL = "/user/article";
export const FAQS_LIST = "/user/faqs";
export const RELATED_CATEGORY_ARTICLES = "/user/related-catgeory-articles";
export const RELEASE_DETAIL = "/user/release-note";
export const RELATED_RELEASES = "/user/related-release-notes";
export const USER_API = "/user";
export const SPOTIFY_USER_API = "/spotify-users";
export const AUTH = "/auth";
export const SIGNUP_API = "/signup";
export const ME = "/me";
export const ARTIST_API = "/artist";
export const ACTIVE_DISTRIBUTORS = "/active-distributors";
export const REPORT = "/report";
export const DISTRIBUTOR_REPORTS_API = "/distributor-reports";
export const DISTRIBUTOR_API = "/distributor";
export const OTHER_REPORTS_API = "/other-reports";
export const STREAMING = "/streaming-income";
export const SEND_REPORTS_API = "/send-reports";
export const ADMIN = "/admin";
export const WHITELABEL_INSTANCE = "/whitelabel/instance";
export const FUNDING_OPTION = "/offer-generation";
export const ARTIST_ESTIMATE = "/user-enrollment";
export const NAVIGATION_STATUS_API = "/navigation-status";
export const VERIFY_STREAMING_INCOME = "/update-streaming-income";
export const SIGNIN_API = "/signin";
export const SIGNIN_SECURE_API = "/secure-link";
export const FORGOT_PASS_API = "/forgot-password";
export const OFFERS_API = "/estimated-offer";
export const VALIDATE_EMAIL = "/validate-email";
export const REPORT_AVAILABILITY = "/report-availability";
export const SEGMENT_DATA = "/segment/data";

const HOME_REGEX_STR = "^/$";
export const HOME_REGEX = new RegExp(HOME_REGEX_STR);
export const AGENT = "/offers";
export const AGENT_HOME = `${AGENT}/:agent_id`;
const AGENT_REGEX_STR = `${AGENT}/.*`;
export const AGENT_REGEX = new RegExp(AGENT_REGEX_STR);
export const ARTICLE_DESC = "/article-template";
const ARTICLE_REGEX_STR = `${ARTICLE_DESC}/.*`;
export const ARTICLE_REGEX = new RegExp(ARTICLE_REGEX_STR);
export const ARTICLE_TEMPLATE = `${ARTICLE_DESC}`;
export const RELEASE_DESC = "/release-template";
const RELEASE_REGEX_STR = `${RELEASE_DESC}/.*`;
export const RELEASE_REGEX = new RegExp(RELEASE_REGEX_STR);
export const RELEASE_TEMPLATE = `${RELEASE_DESC}`;
export const TESTIMONIAL_DESC = "/testimonial-template";
const TESTIMONIAL_REGEX_STR = `${TESTIMONIAL_DESC}/.*`;
export const TESTIMONIAL_REGEX = new RegExp(TESTIMONIAL_REGEX_STR);
export const TESTIMONIAL_TEMPLATE = `${TESTIMONIAL_DESC}`;
export const HOME_AGENT_REGEX = new RegExp(
  `(${HOME_REGEX_STR}|${AGENT_REGEX_STR | ARTICLE_REGEX_STR})`
);