import React, { Component } from "react";
import styles from "./Press.module.scss";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import BackgroundImage from "../../assets/SVGs/bullet.svg";
import { ReactComponent as PreviousArrow } from "../../assets/SVGs/previousArrow.svg";
import { ReactComponent as NextArrow } from "../../assets/SVGs/nextArrow.svg";
import axios from "axios";
import "swiper/css";
import "swiper/css/bundle";
import Stack from "@mui/material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { API_URL, CONTENT_LIST } from "../../routes/constants";
import Divider from "../../components/Divider/Divider";

class Press extends Component {
  state = {
    radio: "",
    page: 1,
    showAlert: false,
    alertText: "",
    articleList: [],
    selectedValue: "all",
    activeId: 0,
    activeTab: false,
    searchValue: "",
    showSearchText: false,
    activePage: 1,
    total: 0,
    totalPage: 0,
    selectedValueText: "",
    searchValueText: "",
    releasePage: 1,
    releaseTotal: 0,
    releaseTotalPage: 0,
    releaseList: [],
    releaseLoader: false,
  };
  slides = [];

  handleSelectChange = (e) => {
    this.setState({ selectedValue: e.target.value });
  };

  getSearchValue = (fromClear, tag) => {
    return this.state?.searchValue?.length && !fromClear && !tag
      ? `&search=${encodeURIComponent(this.state.searchValue)}`
      : "";
  };

  getSelectValue = (fromSubmit) => {
    return fromSubmit ? `&searchType=${this.state.selectedValue}` : "";
  };

  getTagValue = (tag) => {
    return tag ? `&searchType=tag&search=${tag}` : "";
  };

  handlePageChange = (event, value) => {
    this.setState({ page: value }, this.getListData);
  };

  handleReleasePageChange = (event, value) => {
    this.setState({ releasePage: value }, this.getReleaseListData);
  };

  getListData = (fromSubmit = false, tag, fromClear = false) => {
    const requestUrl = `${API_URL}${CONTENT_LIST}?limit=3&page=${
      this.state.page
    }&type=News&category=Press${this.getSearchValue(
      fromClear,
      tag
    )}${this.getSelectValue(fromSubmit)}${this.getTagValue(
      tag
    )}&sort=-publishDate`;
    axios
      .get(requestUrl)
      .then((res) => {
        this.setState({
          articleList: res.data.data.list,
          totalPage: res.data.data.totalPages,
          total: res.data.data.total,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getReleaseListData = (fromSubmit = false, tag, fromClear = false) => {
    this.setState({ releaseLoader: true });
    const requestUrl = `${API_URL}${CONTENT_LIST}?limit=3&page=${
      this.state.releasePage
    }&type=News&category=${encodeURIComponent(
      "Release Notes"
    )}${this.getSearchValue(fromClear, tag)}${this.getSelectValue(
      fromSubmit
    )}${this.getTagValue(tag)}&sort=-publishDate`;
    axios
      .get(requestUrl)
      .then((res) => {
        this.setState({
          releaseList: res.data.data.list,
          releaseTotalPage: res.data.data.totalPages,
          releaseTotal: res.data.data.total,
          releaseLoader: false,
        });
      })
      .catch((err) => {
        this.setState({ releaseLoader: false });
      });
  };

  componentDidMount() {
    this.getListData();
    this.getReleaseListData();
  }

  render() {
    return (
      <>
        {!this.state.loader ? (
            this.state.articleList?.length !== 0 ? (
              <div className={styles.articleList}>
                <h2 className={styles.pressTitle}>Press</h2>
                <div className={`${styles.gridContainer} ${styles.pressPage}`}>
                  {this.state.articleList?.map((list, i) => (
                  <div
                      className={`${styles.articleContainer} ${styles.centerAlign}`}
                  >
                      <Divider horizontal width={"2px"}></Divider>
                      <p className={`${styles.title}`}>
                      {list.title}
                      </p>
                      <p className={`${styles.author}`}>
                      {list.author}
                      </p>
                      <p className={styles.desc}>
                      {list.description.length > 135
                          ? list.description + "..."
                          : list.description}
                      </p>

                      {list.sourceUrl && (
                      <p
                          className={` ${styles.articleLink} ${styles.paddingBottom}`}
                      >
                          <a href={list.sourceUrl}>READ ARTICLE »</a>
                      </p>
                      )}
                  </div>
                  ))}
                </div>
                {this.state.total > 3 && (
                  <div className={`${styles.articlePagination} ${styles.marginTop}`}>
                      <Stack alignItems="center">
                      <Pagination
                          className={styles.pagination}
                          count={this.state.totalPage}
                          size="small"
                          onChange={this.handlePageChange.bind(this)}
                          activeClass={styles.active}
                          sx={{
                          "& .MuiPaginationItem-page": { color: "transparent" },
                          "& .Mui-selected": {
                              backgroundImage: `url(${BackgroundImage})`,
                              backgroundColor: "transparent",
                          },
                          }}
                          renderItem={(item) => (
                          <PaginationItem
                              slots={{
                              previous: PreviousArrow,
                              next: NextArrow,
                              }}
                              {...item}
                          />
                          )}
                      />
                      </Stack>
                  </div>
                )}
              </div>
            ) : ( null ) // hide section if no data
        ) : (
          <div className={styles.articleList}>
            <div className={styles.noData}>
              <FontAwesomeIcon icon={faCircleNotch} className="spin" />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Press;
