import React from "react";
import "./About.scss";
import branding from "../../assets/SVGs/chordCash-Logomark.svg";
import capital from "../../assets/SVGs/money-coin-lined.svg";
import eContract from "../../assets/SVGs/file-sign.svg";
import endToEnd from "../../assets/SVGs/swap-switch-arrows.svg";
import ownership from "../../assets/SVGs/checkmark-circle-outlined.svg";
import shield from "../../assets/SVGs/steps-path.svg";
import terms from "../../assets/SVGs/file-contract.svg";
import tool from "../../assets/SVGs/slider.svg";
import {ContactUsForm,} from "../../components";


const LINES = {
	left: [
		{icon: tool, alt:'', text: 'Self serve tool that clients and prospects can use to design their own funding options'},
		{icon: branding, alt:'', text: 'Custom Branding and integration into your mobile and web presence'},
		{icon: endToEnd, alt:'', text: 'End-to-end, lead-to-closing-to-servicing solution'},
		{icon: capital, alt:'', text: 'Capital to fund advances (or you can provide your own capital - it’s your choice)'},
	],
	right: [
		{icon: ownership, alt:'', text: 'Funding that doesn’t sacrifice ownership'},
		{icon: terms, alt:'', text: 'Flexible terms'},
		{icon: shield, alt:'', text: 'Transparent process'},
		{icon: eContract, alt:'', text: 'Electronic contracts for easy, simple closing'},
	],
}

function About() {

	return (
		<div id="about" className="about">
			<h2>Why chordCash?</h2>
			<p>chordCashAI funding provides benefits to both the partner company and their clients</p>
			<div className="about-details">
				<div className="about-details-left">
					<h3>Artist Service Companies Get...</h3>
					<div className="content">
						{
							LINES.left.map((e,i) => {
								return (
									<div key={i} className="c-line">
										<img className="c-l-icon" src={e.icon} alt={e.alt} />
										<div className="c-l-text">{e.text}</div>
									</div>
								)
							})
						}
					</div>
					<h3>Artists & Labels Get...</h3>
					<div className="content">
						{
							LINES.right.map((e,i) => {
								return (
									<div key={i} className="c-line">
										<img className="c-l-icon" src={e.icon} alt={e.alt} />
										<div className="c-l-text">{e.text}</div>
									</div>
								)
							})
						}
					</div>
				</div>
				<div className="about-details-right">
					<ContactUsForm />
				</div>
			</div>
			
		</div>
	)
}

export default About;