import React, { Component } from "react";
import "./Partners.scss";
import horusLogo from "../../assets/images/horus-logo.webp";
import OpenPlayLogo from "../../assets/images/OpenPlay.webp";
import soundplateLogo from "../../assets/images/soundplate-logo.webp";
import A2IMLogo from "../../assets/images/A2IM-Full-Logo_White-768x344.webp";
import tooLostLogo from "../../assets/images/too-lost-logo copy.webp";
import unitedMastersLogo from "../../assets/images/united-masters-logo.webp";
import mogulLogo from "../../assets/images/Mogul.webp";



class Partners extends Component {
	constructor() {
		super();
		this.state = {
			partners: [
				{logo: unitedMastersLogo, alt:'United Masters Logo', link: 'https://unitedmasters.com'},
				{logo: tooLostLogo, alt:'tooLost Logo',link: 'https://toolost.com'},
				{logo: horusLogo, alt:'Horus Logo', link: 'https://horusmusic.global'},
				{logo: soundplateLogo, alt:'Soundplate Logo', link: 'https://soundplate.com'},
				{logo: A2IMLogo, alt:'A2IM Logo', link: 'https://a2im.org/'},
				{logo: OpenPlayLogo, alt:'OpenPlay Logo', link: 'https://openplay.co/'},
				{logo: mogulLogo, alt:'Mogul Logo', link: 'https://www.usemogul.com/'},
			]
		};
	}

	render() {
		return (
			<div className="partners">
				<div className="partners-title">UTILIZED BY</div>
				<div className="divider"></div>
				<div className="partners-list">
					{
						this.state.partners.map((e,i) => {
							return (
								<a key={i} className="partner" href={e.link} target="blank">
									<img className="partner-logo" alt={e.alt} src={e.logo} />
								</a>
							)
						})
					}
				</div>
			</div>
				
		)
	}
}

export default Partners;