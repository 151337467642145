import React, { Component } from "react";
import styles from "./Input.module.scss";


class Input extends Component {
	constructor() {
		super();
		this.state = {
			value: '',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		this.setState({value: e.target.value},() => {
			if (this.props.return) {
				this.props.return(this.props.placeholder,this.state.value);
			}
		});
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.clear && this.props.clear && this.state.value !== '') {
			this.setState({value: ''},() => {
				this.props.return(this.props.placeholder,this.state.value);
			})
		}
	}

	render() {
		return (
			<div className={styles.inputContainer}>
				{this.props.label ? (<label for={this.props.value}>{this.props.label}</label>) :(null)}
				<input
					className={this.props.className}
					type={this.props.type || 'text'}
					placeholder={this.props.placeholder || ""}
					autoComplete={this.props.autocomplete || "off"}
					value={this.state.value}
					id={this.state.value}
					required={this.props.required || false}
					onChange={(e) => this.handleChange(e)}
				/>	
			</div>
		)
	}
}

export default Input;