import React from "react";
import "./Landing.scss";
import {
	Nav,
} from "../../sections";


function Landing(props) {
	return (
		<div id="landing" className="landing">
			<Nav />
			<div className="landing-container">
						<h1>funding and empowering companies that serve independent artists and labels</h1>
						<p>Our white label product lets distributors, indie labels and artist service companies provide flexible funding to their clients to fuel their careers without giving up ownership.</p>
						<a href="#about" alt="" className="landing-read-more">Contact Us</a>
			</div>
		</div>
	)
}

export default Landing;