import React, { Component } from "react";
import "./FormError.scss";
import "../ContactUsForm.scss";
import Button from "../../Button";
import { ReactComponent as AlertIcon } from "../../../assets/SVGs/Alert.svg";

class FormError extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
        <div className="contact-us-form">
            <div id="err-icon">
                <AlertIcon />
            </div>
            <div id="err-text">
                <div className="title">Error!</div>
                <div className="subtitle">
                Your form failed to submit!
                <div>Try again in a few minutes.</div>
                </div>
            </div>
            <Button full onClick={this.props.onClick}>
            RETURN TO FORM »
            </Button>
        </div>
    );
  }
}

export default FormError;
