import React, { Component } from "react";
import './App.scss';
import MainRoutes from './routes/index';
import {
  MOBILE_BREAK,
} from "./constants/constants";


class App extends Component {
  constructor() {
    super();
    this.state = {
      mobile: false,
      screenSize: 0,
    };
  }
  handleResize = () => {
    const screenStatus = window.screen.width < MOBILE_BREAK;
    this.setState({screenSize: window.screen.width});
    if (this.state.mobile !== screenStatus) {
      this.setState({mobile: screenStatus});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize',this.handleResize);
  }

  componentDidMount() {
    if (window.screen.width < MOBILE_BREAK) {
      this.setState({mobile: true});
    }
    this.setState({screenSize: window.screen.width});
		window.addEventListener('resize',this.handleResize);
  }

	render() {
		return (
      <MainRoutes mobile={this.state.mobile} screenSize={this.state.screenSize} />
		)
	}
}

export default App;
