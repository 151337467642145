import React from "react";
import "./Policy.scss";
import { Nav, Footer } from "../../sections";
import { BackToTop } from "../../components";


function Policy(props) {
	return (
		<div className="policy">
			<Nav />
			<div className="policy-title">
				<h1>chordCash / beatBread Policy on Multiple Funding Applications</h1>
			</div>
			<div className="policy-body">
				<div className="policy-text">
					<p>chordCash is a funding and white label partner to several independent labels, artist services and distribution companies. We are owned by beatBread, a funding platform that utilizes the same underlying technology to offer advances to independent artists.</p>
					<p>At times, artists and managers may submit their information to:</p>
					<ul>
						<li>
							more than one white label partner who use the chordCash system OR
						</li>
						<li>
							a white label partner and beatBread OR
						</li>
						<li>
							beatBread and multiple white label partners
						</li>
					</ul>
					<p>
						This policy is designed to inform our partners what will happen when any of the “multi-application” scenarios occur, but in short, we will be neutral. beatBread and chordCash both operate under an “open platform” ethos. Artist choice is core to the beatBread and chordCash mission. We will never treat artists as “property” of one of our partners.
					</p>
					<ul>
						<li>
							There is no concept of exclusivity or exclusivity periods on beatBread or chordCash that locks an artist in to receive quotes or contract with one partner or another
						</li>
						<li>
							There is no concept of privileged partners, or tiering between partners
						</li>
					</ul>
					<p className="pt-bold pt-underline">
						More specifically…
					</p>
					<p className="pt-bold pt-italics">
						Things we will do when we receive multiple applications:
					</p>
					<ol>
						<li>
							Once an artist application progresses to the “upload reports” stage, we will inform each partner that the artist has uploaded their reports somewhere else in our system, at least one other time
						</li>
						<li>
							We will present offers from each partner and beatBread as quickly as possible, in all instances
						</li>
						<li>
							We will be factual about the offers presented to artists, factoring in the distribution costs or other economic arrangements that that partner has made with chordCash.
						</li>
					</ol>
					<p className="pt-italics">
						Note: Artists through one white label partner may receive more favorable terms on another white label partner. This will NEVER have anything to do with beatBread/chordCash ‘playing favorites’ or having more favorable terms with one partner or another. Offers to artists only reflect the costs (distribution or otherwise) that a partner wants to have factored in the deal, and the algorithmic parameters each partner chooses in the onboarding process.
					</p>
					<p className="pt-bold pt-italics">
						Things we will NOT do when we receive multiple applications:
					</p>
					<ol>
						<li>
							Actively notify any partner who has not yet received an application of the fact that a specific artist has applied for funding on another partner so that it may reach out to that artist
						</li>
						<li>
							Solicit “beatBread Direct” business to an artist who has applied on our partner sites. We <span className="pt-underline">will</span> passively take applications from artists who decide to use our system, but we will never actively solicit them as a result of their submitting information on a partner site
						</li>
						<li>
							Use the information to privilege beatBread’s business—We are indifferent to how our deals are sourced–<span className="pt-italics">remember, however, we will present offers to applicants in line with the reports provided and the offer settings that each partner has chosen. If an artist applies on beatBread and/or one or more white label partner, and the costs to the artist are lower with one partner than another partner to which they have applied, we will not hide that fact from the artist. <span className="pt-underline pt-italics">Every partner has an opportunity to make the offers showing on its white label instance as good, or even better than beatBread’s, if it so chooses.</span></span>
						</li>
						<li>
							Disclose any information about the identity of other partners who may also have received a submission, or disclose how many other partner sites an artist has submitted an application
						</li>
						<li>
							Disclose any information about the relative deal terms that an artist may be receiving from one partner to another partner
						</li>
						<li>
							Prevent an artist from moving its distribution from one of our partners to another. (Artist empowerment and choice are our North Star, and promoting choice and empowerment for artists was the reason beatBread was founded)
						</li>
					</ol>
				</div>
			</div>
			<Footer />
			{
				!props.mobile ?
					<BackToTop
						appear={200}
					/>
				:
					<></>
			}
		</div>
	)
}

export default Policy;