import React from "react";
import "./Footer.scss";
import {
	POLICY,
} from "../../routes/constants";

let currentYear = new Date().getFullYear();

function Footer() {

	return (
		<div className="footer">
				<a href={POLICY} alt="" className="footer-link">
					Multiple Funding Application Policy
				</a>
				<div className="footer-legal">-</div>
				<div className="footer-legal">All Rights Reserved &copy; {currentYear}</div>
		</div>
	)
}

export default Footer;