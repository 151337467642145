import React from "react";
import "./Home.scss";
import {
	Landing,
	About,
	Footer,
	Press,
	Partners
} from "../../sections";
import {
	BackToTop,
} from "../../components";


function Home(props) {

	return (
		<div className="home">
			<Landing {...props} />
			<Partners {...props} />
			<About {...props} />
			<Press {...props} />
			<Footer {...props} />
			{
				!props.mobile ?
					<BackToTop
						appear={200}
					/>
				:
					<></>
			}
		</div>
	)
}

export default Home;